import {UserInfo} from "@/models/user/UserInfo";

export class DisdiInfo{
    id:string = "";
    locationSecondaryId:number = -1;
    registrationId:number = -1;
    categoryId: string = "";
    categoryTitle: string = "";
    categoryIconName: string = "";
    diaryDescription:string = "";
    hasAttachment:boolean = false;
    comment:string = "";
    isCompleted:boolean = false;
    createDate:string = ""; // date format
    registrationEnd:string|null = null; // date format
    registrationStart:string|null = null; // date format
    companyName:string = "";
    isIdsUser:boolean = false;
    userFullname:string = "";
    userPhoneNumber:string = "";
    userEmail:string = "";
    identificationNumber:string = "";
    kid:string = "";
}

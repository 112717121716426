import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {ErrorLocationType, HttpType} from "@/utilities/enums/Enums";
import {CategoryInfo} from "@/models/category/CategoryInfo";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {CategoryRequest} from "@/models/api/Requests";
import {
    CategoriesResponse,
    CategoryByIdResponse,
    GeneralResponse,
    GuidResponse,
    IconsResponse
} from "@/models/api/Responses";
import NullOrUndefinedHelper from "@/utilities/helpers/NullOrUndefinedHelper";

export class CategoryService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor() {
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getCategories(viewtype: string = "display", errorLocation:ErrorLocationType):Promise<Array<CategoryInfo> | null> {
        const uri = this.uriHelper.category.getCategoriesPath() + `/viewtype/${viewtype}`;

        const listResp = (await this.webCaller.HttpSend(HttpType.get, uri)) as CategoriesResponse;
        if (listResp === null || listResp === undefined || listResp.statusCode !== 0){
            ErrorHandler.handleError(listResp, errorLocation);
            return null;
        }

        return listResp.categories;
    }

    public async addOrUpdateCategory(category:CategoryInfo, errorLocation:ErrorLocationType):Promise<CategoryInfo | null>{
        const uri = NullOrUndefinedHelper(category.id)
            ? this.uriHelper.category.getCategoriesPath()
            : this.uriHelper.category.getCategoryByIdPath(category.id);
        const requestBody = new CategoryRequest(category);
        const reqType = NullOrUndefinedHelper(category.id)
            ? HttpType.post
            : HttpType.put;

        const categoryResp = (await this.webCaller.HttpSend(reqType, uri, requestBody)) as GuidResponse | GeneralResponse;
        if (categoryResp === null || categoryResp === undefined || categoryResp.statusCode !== 0) {
            ErrorHandler.handleError(categoryResp, errorLocation);
            return null;
        }

        if (NullOrUndefinedHelper(category.id)) category.id = (categoryResp as GuidResponse).id;

        return category;
    }

    public async deleteCategory(id:string, errorLocation:ErrorLocationType):Promise<boolean>{
        const uri = this.uriHelper.category.getCategoryByIdPath(id);

        const delResp = (await this.webCaller.HttpSend(HttpType.delete, uri)) as GeneralResponse;
        const success = delResp !== null && delResp !== undefined && delResp.statusCode === 0
        if (!success) ErrorHandler.handleError(delResp, errorLocation);

        return success;
    }

    public async getAllIconNames(errorLocation:ErrorLocationType):Promise<Array<string>|null>{
        const uri = this.uriHelper.category.getAllIconNamesPath();

        const listResp = (await this.webCaller.HttpSend(HttpType.get, uri)) as IconsResponse;
        if (listResp === null || listResp === undefined || listResp.statusCode !== 0){
            ErrorHandler.handleError(listResp, errorLocation);
            return null;
        }

        return listResp.iconInfo?.map(ii => ii.name) ?? null;
    }

    public async getAvailableIconNames(errorLocation:ErrorLocationType):Promise<Array<string>|null>{
        const uri = this.uriHelper.category.getAvailalbleIconNamesPath();

        const listResp = (await this.webCaller.HttpSend(HttpType.get, uri)) as IconsResponse;
        if (listResp === null || listResp === undefined || listResp.statusCode !== 0){
            ErrorHandler.handleError(listResp, errorLocation);
            return null;
        }

        return listResp.iconInfo?.map(ii => ii.name) ?? null;
    }
}